/* app global css */
@font-face {
  font-family: 'Inter';
  src: local('Merienda'),
    url(../assets/font/Inter_24pt-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Merienda'),
    url(../assets/font/Inter_24pt-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Merienda'),
    url(../assets/font/Inter_24pt-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSans';
  src: local('Merienda'),
    url(../assets/font/IBMPlexSans-Regular.ttf) format('truetype');
  font-display: swap;
}

:root {
  /* color */
  --content-text-grey: #666666;
  --inside-textbox: #d1d1d1;
  --inactive-textbox: #adadad;
  --base-white: #ffffff;
  --main-text-title: #1c1c1c;
  --main-yellow: #fcb400;
  --yellow-for-link: #f79e1c;
  --graphic-light-yellow: #fcf1d1;
  --graphic-medium-yellow: #fbeabf;
  --graphic-blue: #bdd9f7;
  --graphic-dark-green: #677043;
  --graphic-light-green: #c3c873;
  --footer: #f7f7f7;
  --divider-line: #d9d9d9;
  --graphic-dark-grey: #393939;
  --graphic-logo-grey: #333333;

  /* font size */
  --title-text: 20px;
  --content-text: 16px;
  --textfield-label: 16px;
  --textfield-helper-text: 14px;
}

body {
  font-family: Inter;
  color: var(--main-text-title);
}

.bg-primary {
  background-color: var(--main-yellow) !important;
}

.q-btn {
  font-weight: normal;
  text-transform: none;
}

/* Dialog Design https://xd.adobe.com/view/f490bea9-6112-4553-a500-4f1c88146b1b-a1c3/screen/3808d8e8-90e0-4007-bf5a-b3ff3d5181e8/specs/ */

.q-dialog__inner--minimized > div {
  max-height: calc(100dvh - 48px);
}
.q-dialog__inner > .q-card {
  border-radius: 12px;
  min-width: 300px;
  min-height: 165px;
  width: 300px;
}
.q-dialog__title {
  padding: 27px 20px 9px 20px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #1c1c1c;
}
.q-dialog__message {
  padding: 0 20px 27px 20px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #666666;
}
.q-dialog .q-card__actions--horiz {
  box-shadow: inset #d9d9d9 0 1px 0 0 !important;
  padding: 0;
  height: 52px;
  align-items: stretch;
}
.q-dialog .q-card__actions--horiz > .q-btn-item {
  padding: 0;
  flex: 1 1 0;
  font-size: 16px;
  border-radius: 0;
  color: #666666 !important;
  text-transform: none;
}
.q-dialog .q-card__actions--horiz > .q-btn-item .q-focus-helper {
  background: none !important;
}
.q-dialog .q-card__actions--horiz > .q-btn-item.q-focusable:focus {
  font-weight: bold;
  color: #fcb400 !important;
}
.q-dialog .q-card__actions--horiz > .q-btn-item + .q-btn-item {
  margin-left: 0;
  box-shadow: inset #d9d9d9 1px 0 0 0;
}
.q-dialog.large .q-dialog__inner > .q-card {
  width: 100%;
}
@media screen and (min-width: 640px) {
  .q-dialog.large .q-dialog__inner > .q-card {
    max-width: 800px;
    max-height: 600px;
  }
  .q-dialog.large .q-dialog__title {
    padding: 37px 52px 9px 52px;
  }
  .q-dialog.large .q-dialog__message {
    padding: 0 52px 37px 52px;
  }
}

/* q-notify */
.q-notify-ok {
  background-color: var(--q-positive);
}
.q-notify-error {
  background-color: var(--q-negative);
}

/* q-input */

.q-field--with-bottom {
  padding-bottom: 30px;
}
.q-field--outlined .q-field__control {
  padding: 0 18px 0 12px;
  color: #666666;
}
.q-field__control {
  background-color: transparent;
  height: 44px;
}
.q-field--readonly .q-field__control,
.q-field--disabled .q-field__control {
  background-color: #fbfbfb;
}
.q-field--outlined .q-field__control {
  padding: 0 4px 0 14px;
  border-radius: 10px;
}
.q-field--outlined.q-field--rounded .q-field__control {
  padding: 0 19px;
  border-radius: 28px;
}
.q-field--outlined.q-field__control:after,
.q-field--outlined.q-field--readonly .q-field__control:after,
.q-field--outlined.q-field--disabled .q-field__control:after,
.q-field--outlined.q-field--focused .q-field__control:after {
  border: 1px solid #d5d5d5;
}
.q-field--outlined .q-field__control:after,
.q-field--outlined .q-field__control:before {
  height: 100%;
}
.q-field--outlined.q-field--highlighted .q-field__control:after {
  border: 1px solid currentColor;
}
.q-field--outlined.q-field--readonly .q-field__control:before {
  border: none;
}
.q-field__control-container {
  height: 100%;
}
.q-field .q-field__native {
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  color: #666666;
}
.q-field__native::placeholder {
  color: #b5b5b5;
}
.q-field--readonly .q-field__native,
.q-field--disabled .q-field__native {
  color: #d1d1d1;
}
.q-field__marginal {
  height: 100%;
}
.q-field__prepend {
  padding-right: 0;
}
.q-field__bottom {
  padding: 10px 0 0 0;
  color: #666666;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 40rem #ffffff inset;
}

@media (min-width: 640px) {
  .q-field .q-field__native {
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
  }
  .q-field__control,
  .q-field__marginal {
    height: 58px;
  }
  .q-field--outlined .q-field__control {
    padding: 0 7px 0 19px;
  }
  .q-field--outlined.q-field--rounded .q-field__control {
    padding: 0 24px;
  }
  .q-field__prepend {
    padding-right: 12px;
  }
}
